<template>
  <IonItem class="background-transparent item" color="light" :lines="lines">
    <IonLabel
      v-if="label"
      color="medium"
      :class="[required ? 'bold' : '']"
      position="stacked"
    >
      {{ label }}
    </IonLabel>
    <Hint v-if="hint" class="hint" :hint="hint" />
    <slot></slot>
    <span v-if="error && typeof error === 'string' && error.length > 0" class="error">{{
      error
    }}</span>
    <span
      v-else-if="success && typeof success === 'string' && success.length > 0"
      class="success"
      >{{ success }}</span
    >
  </IonItem>
</template>
<script>
import { IonLabel, IonItem } from "@ionic/vue";
import Hint from "../Hint.vue";

export default {
  name: "ItemInput",
  components: {
    IonLabel,
    IonItem,
    Hint,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    lines: {
      type: String,
      default: "none",
    },
    error: {
      type: [String, Boolean],
      default: false,
    },
    success: {
      type: [String, Boolean],
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: null,
    },
  },
  methods: {},
};
</script>
<style scoped>
.error {
  color: var(--ion-color-danger) !important;
  font-size: 12px;
}
.success {
  color: var(--ion-color-success) !important;
  font-size: 12px;
}
.item {
  --border-radius: 8px;
  margin-bottom: 8px;
}
.bold {
  font-weight: bold;
}
.hint {
  position: absolute;
  right: 0;
  top: 0;
}
</style>
