import * as ROUTES from './routes';

export default [
  // Center
  ROUTES.routeCenterMain(),
  ROUTES.routeCenterPosts(),
  ROUTES.routeCenterPostsTag(),
  ROUTES.routeCenterPostsCategory(),
  ROUTES.routeCenterLessons(),
  ROUTES.routeCenterLessonsTheme(),
  ROUTES.routeCenterLessonsCategory(),
  ROUTES.routeCenterVideo(),
  ROUTES.routeMainVideos(),
  ROUTES.routeProfileVideos(),
  ROUTES.routeCenterOrganisations(),
  ROUTES.routeCenterOrganisationsCategory(),
  ROUTES.routeCenterExperts(),
  ROUTES.routeCenterStock(),
  ROUTES.routeCenterQuestion(),
  ROUTES.routeCenterQuestions(),
  ROUTES.routeCenterQuestionsTheme(),
  ROUTES.routeCenterQuestionsCategory(),
  ROUTES.routeCenterProfile(),
  ROUTES.routeCenterBanner(),
  ROUTES.routeCenterTheme(),
  ROUTES.routeCenterVideos(),
  ROUTES.routeCenterSalesCompany(),
  ROUTES.routeCenterMyCalendar(),
  ROUTES.routeWebCenterMyCompanies(),
  ROUTES.routeWebCompanyTimetable(),
  ROUTES.routeWebCompanyRecord(),
  ROUTES.routeWebCompanyServices(),
  ROUTES.routeCenterMyCompanies(),
  ROUTES.routeCenterVideoConference(),
  ROUTES.routeSchedule(),
  ROUTES.routeStudentFiles(),
  // Membership
  ROUTES.routeMembershipProfile(),

  // All other

  ROUTES.routeSignIn(),
  ROUTES.routeProfileEdit(),
  ROUTES.routeBalance(),
  ROUTES.routeCenterCompany(),
  ROUTES.routeSchool(),
  ROUTES.routePostByArticle(),
  ROUTES.routeUserByArticle(),

  ROUTES.routeTickets(),
  ROUTES.routeTicketsItems(),
  ROUTES.routeServicesGroups(),
  ROUTES.routeStudentsGroups(),
  // Profile from Moedvizh

  ROUTES.routeFormsAnswersByClient(),
  ROUTES.routeVisitsStudents(),
  // ROUTES.routeFormsQuestionsByClient(),

  ROUTES.routeForms(),
  ROUTES.routeStockByCompany(),
  ROUTES.routeFormQuestions(),
  ROUTES.routeFormAnswers(),
  ROUTES.routeServices(),
  ROUTES.routeDigitalGoods(),
  // ROUTES.routeService(),
  ROUTES.routeTeachers(),
  ROUTES.routeTeacher(),
  ROUTES.routeStudents(),
  ROUTES.routeStudent(),
  ROUTES.routeAdministrators(),
  ROUTES.routeFinance(),
  ROUTES.routeRequests(),
  ROUTES.routeWorkTimes(),
  ROUTES.routeTimetable(),
  ROUTES.routeSchoolBonuses(),
  ROUTES.routeFormsByClient(),
  ROUTES.routeFilesByClient(),
  ROUTES.routeVideoconferenceByCompany(),
  ROUTES.routeNotifications(),
  ROUTES.routeMembership(),
  ROUTES.routeCenterChat()
];
